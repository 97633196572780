<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="metricPageViewsDailyLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-card-body class="mb-2">
          <b-row class="mb-2">
            <b-card-title class="col">
              {{ $t('dashboard.newPhoneNumbers') }} (napi bontás)
            </b-card-title>
            <b-col>
              <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                  <div class="d-flex align-items-center">
                    <feather-icon
                        icon="CalendarIcon"
                        size="16"
                    />
                    <flat-pickr
                        v-model="newPhoneNumbers.daily.rangePicker"
                        :config="{ mode: 'range'}"
                        class="form-control flat-picker bg-transparent border-0 shadow-none"
                        placeholder="YYYY-MM-DD"
                        @on-change="initNewPhoneNumbersDaily"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <chartjs-component-line-chart-wrapper
                  v-if="!metricPageViewsDailyLoading && newPhoneNumbers.daily.datasets.length"
                  :height="350"
                  :data="newPhoneNumbers.daily"
                  :options="newPhoneNumbers.daily.options"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>

    <b-card no-body>
      <b-overlay
          :show="metricPageViewsMonthlyLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-card-body class="mb-2">
          <b-row class="mb-2">
            <b-card-title class="col">
              {{ $t('dashboard.newPhoneNumbers') }} (havi bontás)
            </b-card-title>
            <b-col>
              <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                  <div class="d-flex align-items-center">
                    <v-select
                        v-model="newPhoneNumbers.monthly.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="newPhoneNumbers.monthly.yearSelect"
                        @input="initNewPhoneNumbersMonthly"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <chartjs-component-line-chart-wrapper
                  v-if="!metricPageViewsMonthlyLoading && newPhoneNumbers.monthly.datasets.length"
                  :height="350"
                  :data="newPhoneNumbers.monthly"
                  :options="newPhoneNumbers.monthly.options"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>

    <b-card no-body>
      <b-overlay
          :show="metricDailySearchesLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-card-body class="mb-2">
          <b-row class="mb-2">
            <b-card-title class="col">
              {{ $t('dashboard.newSearches') }} (napi bontás)
            </b-card-title>
            <b-col>
              <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                  <div class="d-flex align-items-center">
                    <feather-icon
                        icon="CalendarIcon"
                        size="16"
                    />
                    <flat-pickr
                        v-model="newSearches.daily.rangePicker"
                        :config="{ mode: 'range'}"
                        class="form-control flat-picker bg-transparent border-0 shadow-none"
                        placeholder="YYYY-MM-DD"
                        @on-change="initNewSearchesDaily"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <chartjs-component-line-chart-wrapper
                  v-if="!metricDailySearchesLoading && newSearches.daily.datasets.length"
                  :height="350"
                  :data="newSearches.daily"
                  :options="newSearches.daily.options"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>

    <b-card no-body>
      <b-overlay
          :show="metricMonthlySearchesLoading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-card-body class="mb-2">
          <b-row class="mb-2">
            <b-card-title class="col">
              {{ $t('dashboard.newSearches') }} (havi bontás)
            </b-card-title>
            <b-col>
              <b-row>
                <b-col class="d-flex align-items-center justify-content-end">
                  <div class="d-flex align-items-center">
                    <v-select
                        v-model="newSearches.monthly.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="newSearches.monthly.yearSelect"
                        @input="initNewSearchesMonthly"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <chartjs-component-line-chart-wrapper
                  v-if="!metricMonthlySearchesLoading && newSearches.monthly.datasets.length"
                  :height="350"
                  :data="newSearches.monthly"
                  :options="newSearches.monthly.options"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import ChartjsComponentLineChartWrapper from '@/views/pages/_components/_chart/ChartjsComponentLineChartWrapper.vue'
import flatPickr from 'vue-flatpickr-component'

import {$themeColors} from '@themeConfig'
import vSelect from "vue-select";

import ChartjsComponentPolarAreaChartWrapper
  from "@/views/pages/_components/_chart/ChartjsComponentPolarAreaChartWrapper.vue";
import ChartjsComponentHorizontalBarChartWrapper
  from "@/views/pages/_components/_chart/ChartjsComponentHorizontalBarChartWrapper.vue";

// colors
const chartColors = {
  primaryColorShade: '#8B76EA',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    ChartjsComponentHorizontalBarChartWrapper,
    BOverlay,
    vSelect,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    ChartjsComponentLineChartWrapper,
    ChartjsComponentPolarAreaChartWrapper,
    flatPickr,
  },
  data() {
    return {
      loading: true,
      metricPageViewsDailyLoading: false,
      metricPageViewsMonthlyLoading: false,
      metricSearchesLoading: false,
      metricDailySearchesLoading: false,
      metricMonthlySearchesLoading: false,
      rangePicker: [],
      datasetSkeleton: {
        label: '',
        data: [],
        lineTension: 0,
        backgroundColor: chartColors.blueColor,
        pointStyle: 'circle',
        borderColor: 'transparent',
        pointRadius: 0.5,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: chartColors.blueColor,
        pointHoverBorderColor: $themeColors.light,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'top',
          align: 'start',
          display: false,
          labels: {
            usePointStyle: true,
            padding: 15,
            boxWidth: 8,
          },
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: 'transparent',
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'transparent',
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: 100,
                fontColor: chartColors.labelColor,
              },
              scaleLabel: {
                display: true,
              },
            },
          ],
        },
      },
      polarAreaChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 10,
            fontColor: '#6e6b7b',
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scale: {
          scaleShowLine: true,
          scaleLineWidth: 1,
          ticks: {
            display: false,
            fontColor: '#6e6b7b',
          },
          reverse: false,
          gridLines: {
            display: false,
          },
        },
        animation: {
          animateRotate: false,
        },
      },
      resolutionTime: {label: '60 perc', value: 60},
      resolutionOptions: [
        {label: '5 perc', value: 5},
        {label: '15 perc', value: 15},
        {label: '30 perc', value: 30},
        {label: '60 perc', value: 60}
      ],
      newPhoneNumbers: {
        daily: {
          labels: [],
          datasets: [],
          max: 10,
          stepSize: 1,
          options: [],
          rangePicker: [],
        },
        monthly: {
          labels: [],
          datasets: [],
          max: 10,
          stepSize: 1,
          options: [],
          year: null,
          yearSelect: [],
        },
      },
      newSearches: {
        daily: {
          labels: [],
          datasets: [],
          max: 10,
          stepSize: 1,
          options: [],
          rangePicker: [],
        },
        monthly: {
          labels: [],
          datasets: [],
          max: 10,
          stepSize: 1,
          options: [],
          year: null,
          yearSelect: [],
        },
      },
    }
  },
  created() {
    // set range picker
    const defaultDPStart = this.$helpers.getCurrentDatePlusXDays(-3)
    const defaultDPTo = this.$helpers.getCurrentDate()

    this.newPhoneNumbers.daily.rangePicker.push(defaultDPStart)
    this.newPhoneNumbers.daily.rangePicker.push(defaultDPTo)

    this.newSearches.daily.rangePicker.push(defaultDPStart)
    this.newSearches.daily.rangePicker.push(defaultDPTo)

    this.newPhoneNumbers.monthly.yearSelect = this.generateYearRange(2024)
    this.newSearches.monthly.yearSelect = this.generateYearRange(2024)

    this.initNewPhoneNumbersDaily()
    this.initNewPhoneNumbersMonthly()
    this.initNewSearchesDaily()
    this.initNewSearchesMonthly()
  },
  methods: {
    initNewPhoneNumbersDaily() {
      this.metricPageViewsDailyLoading = true
      this.newPhoneNumbers.daily.datasets = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.newPhoneNumbers.daily.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
      }

      this.$store.dispatch('newPhoneNumbersDaily', params).then(response => {
            this.newPhoneNumbers.daily.labels = response.data.labels

            if (response.data.dataSets.length) {
              const rDataSets = response.data.dataSets

              Object.keys(rDataSets).forEach(key => {
                let color = chartColors.primaryColorShade

                this.newPhoneNumbers.daily.datasets.push({
                  ...this.datasetSkeleton,
                  ...rDataSets[key],
                  ...{
                    backgroundColor: color,
                    pointHoverBackgroundColor: color,
                  },
                })
              })

              this.newPhoneNumbers.daily.max = response.data.max
              this.newPhoneNumbers.daily.stepSize = response.data.stepSize
              this.newPhoneNumbers.daily.options = this.options

              this.newPhoneNumbers.daily.options.scales.yAxes[0].ticks.min = 0
              this.newPhoneNumbers.daily.options.scales.yAxes[0].ticks.max = response.data.max
              this.newPhoneNumbers.daily.options.scales.yAxes[0].ticks.stepSize = response.data.stepSize
            }
      }).finally(() => {
        this.metricPageViewsDailyLoading = false
      })
    },
    initNewPhoneNumbersMonthly() {
      this.metricPageViewsMonthlyLoading = true
      this.newPhoneNumbers.monthly.datasets = []

      const params = {
        year: this.newPhoneNumbers.monthly.year,
      }

      this.$store.dispatch('newPhoneNumbersMonthly', params).then(response => {
        this.newPhoneNumbers.monthly.labels = response.data.labels

        if (response.data.dataSets.length) {
          const rDataSets = response.data.dataSets

          Object.keys(rDataSets).forEach(key => {
            let color = chartColors.primaryColorShade

            this.newPhoneNumbers.monthly.datasets.push({
              ...this.datasetSkeleton,
              ...rDataSets[key],
              ...{
                backgroundColor: color,
                pointHoverBackgroundColor: color,
              },
            })
          })

          this.newPhoneNumbers.monthly.max = response.data.max
          this.newPhoneNumbers.monthly.stepSize = response.data.stepSize
          this.newPhoneNumbers.monthly.options = this.options

          this.newPhoneNumbers.monthly.options.scales.yAxes[0].ticks.min = 0
          this.newPhoneNumbers.monthly.options.scales.yAxes[0].ticks.max = response.data.max
          this.newPhoneNumbers.monthly.options.scales.yAxes[0].ticks.stepSize = response.data.stepSize
        }
      }).finally(() => {
        this.metricPageViewsMonthlyLoading = false
      })
    },
    initNewSearchesDaily() {
      this.metricDailySearchesLoading = true
      this.newSearches.daily.datasets = []

      const rangePickerValue = this.$helpers.getVueFlatpickrValues(this.newSearches.daily.rangePicker, this.$helpers.getCurrentDatePlusXDays(-3), this.$helpers.getCurrentDate())

      const params = {
        from: rangePickerValue[0],
        to: rangePickerValue[1],
      }

      this.$store.dispatch('newSearchesDaily', params).then(response => {
        this.newSearches.daily.labels = response.data.labels

        if (response.data.dataSets.length) {
          const rDataSets = response.data.dataSets

          Object.keys(rDataSets).forEach(key => {
            let color = chartColors.primaryColorShade

            this.newSearches.daily.datasets.push({
              ...this.datasetSkeleton,
              ...rDataSets[key],
              ...{
                backgroundColor: color,
                pointHoverBackgroundColor: color,
              },
            })
          })

          this.newSearches.daily.max = response.data.max
          this.newSearches.daily.stepSize = response.data.stepSize
          this.newSearches.daily.options = this.options

          this.newSearches.daily.options.scales.yAxes[0].ticks.min = 0
          this.newSearches.daily.options.scales.yAxes[0].ticks.max = response.data.max
          this.newSearches.daily.options.scales.yAxes[0].ticks.stepSize = response.data.stepSize
        }
      }).finally(() => {
        this.metricDailySearchesLoading = false
      })
    },
    initNewSearchesMonthly() {
      this.metricMonthlySearchesLoading = true
      this.newSearches.monthly.datasets = []

      const params = {
        year: this.newSearches.monthly.year,
      }

      this.$store.dispatch('newSearchesMonthly', params).then(response => {
        this.newSearches.monthly.labels = response.data.labels

        if (response.data.dataSets.length) {
          const rDataSets = response.data.dataSets

          Object.keys(rDataSets).forEach(key => {
            let color = chartColors.primaryColorShade

            this.newSearches.monthly.datasets.push({
              ...this.datasetSkeleton,
              ...rDataSets[key],
              ...{
                backgroundColor: color,
                pointHoverBackgroundColor: color,
              },
            })
          })

          this.newSearches.monthly.max = response.data.max
          this.newSearches.monthly.stepSize = response.data.stepSize
          this.newSearches.monthly.options = this.options

          this.newSearches.monthly.options.scales.yAxes[0].ticks.min = 0
          this.newSearches.monthly.options.scales.yAxes[0].ticks.max = response.data.max
          this.newSearches.monthly.options.scales.yAxes[0].ticks.stepSize = response.data.stepSize
        }
      }).finally(() => {
        this.metricMonthlySearchesLoading = false
      })
    },
    generateYearRange(startYear) {
      const currentYear = new Date().getFullYear();
      this.newPhoneNumbers.monthly.year = currentYear
      this.newSearches.monthly.year = currentYear
      const years = [];
      for (let year = startYear; year <= currentYear; year++) {
        years.push({
          label: year,
          value: year,
        });
      }
      return years;
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
